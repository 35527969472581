import React from "react"
import { Link } from "gatsby"
import "./valueCard.scss"
import UpIcon from '../../images/icons/up-icon.png'

const ValueCard = (props) => {
    return (
        <div className="valueCardView">
            {props && props.data.map((item) => (
                <div className="w3-col s12 m4">
                    <div className="our-values">
                        <div className="valueCard"><img className="valueCardImage" src={item.url} /></div>
                        <div className="valueCardTitle">{item.title}</div>
                        <div className="valueCardContent">{item.description}</div>
                    </div>
                </div>
            ))}
            <div onClick={() => window.scroll({ top: 230, behavior: "smooth" })} className="w3-hide-medium w3-hide-large up-arrow">
                <img src={UpIcon} />
            </div>
        </div>
    )
}

export default ValueCard
