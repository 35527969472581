import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ValueCard from "../components/valueCard"
import data from "../../Content/about-us-page-content.json"
import ValueCardContent from "../../Content/ValueCardContent.json"
import "./pages.scss"
import QuoteImage from "../images/icons/quote.png"
import valueCard from '../images/icons/valueCard.png'
import ValueCard1 from '../images/icons/valueCard1.png'
import ValueCard2 from '../images/icons/valueCard2.png'
import ValueCard3 from '../images/icons/valueCard3.png'
import ValueCard4 from '../images/icons/valueCard4.jpg'
import DownArrow from '../images/icons/down-arrow.png'
import SmQuotesGroupIcon from '../images/icons/logo-mo.png';
import UpArrow from "../images/icons/up-arrow.png";
import SEO from "../components/seo"
import Slider from "react-slick";

const BeliefImageDe1 = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-belief-de1.png"
const BeliefImageDe2 = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-belief-de2.png"
const BeliefImageDe3 = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-belief-de3.png"
const BeliefImageMo1 = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-belief-mo1.png"
const BeliefImageMo2 = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-belief-mo2.png"
const BeliefImageMo3 = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/about-us/our-belief-mo3.png"

const Logo = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png";

const valueIcons = [valueCard, ValueCard4, ValueCard1, ValueCard2, ValueCard3]
    const image = ValueCardContent.data.valuecard.map((value, idx) => ({
        ...value,
        url: valueIcons[idx]
    }))
ValueCardContent.data.valuecard = image;

const settings = {
    className: "beliefs-slider",
    dots: true,
    arrows: true,
    swipeToSlide: true,
    draggable: true,
    swipe: true,
    slidesToShow: 1,
};

const OurBeliefs = ( <>
     <div className="w3-hide-small ab-us-or-be">
        <Slider {...settings}> 
            <img loading="lazy" className="" src={BeliefImageDe1} />
            <img loading="lazy" className="" src={BeliefImageDe2} />
            <img loading="lazy" className="" src={BeliefImageDe3} />
        </Slider>
    </div>
    <div className="w3-hide-large w3-hide-medium ab-us-or-be">
        <Slider {...settings}> 
            <img loading="lazy" className="" src={BeliefImageMo1} />
            <img loading="lazy" className="" src={BeliefImageMo2} />
            <img loading="lazy" className="" src={BeliefImageMo3} />
        </Slider>
    </div>
</>)
  
const AboutUs = ({ location }) => {
    const [openCls, setOpenCls] = useState('w3-hide-small')
    const [openVal, setOpenVal] = useState('w3-hide-small')

    useEffect (() => {
        if (location.hash == '#beliefs') {
            setOpenCls('w3-show-small');
            setOpenVal('w3-hide-small');
        } else if (location.hash == '#values') {
            setOpenCls('w3-show-small');
            setOpenVal('w3-show-small');
        } else {
            setOpenCls('w3-hide-small');
            setOpenVal('w3-hide-small');
        }
    },[location.hash])
    
        return (
        <div className="about-us-background">
            <div className="w3-hide-large w3-hide-medium" style={{position: "relative"}}>
                <img loading="lazy" className="about-us-os-logo" src={SmQuotesGroupIcon} />
            </div>
            <Layout>
                <SEO title="About Us" />
                <div className="w3-hide-large w3-hide-medium" style={{display: "flex", position: "relative"}}>
                    <div className="super-ho-title">{data.header}</div>
                </div>
                <img loading="lazy" className="super-ho-logo w3-hide-small" src={Logo} />
                <div className="about-us-view">
                    <div className="about-us-header w3-hide-small">{data.header}</div>
                    <div><img loading="lazy" className="quote-logo w3-hide-small" src={QuoteImage} /></div>
                    <div className="w3-hide-large w3-hide-medium about-us-bor"> 
                        <button
                            className="about-us-button about-us-border" 
                            onClick={() => openCls == 'w3-hide-small' ? (setOpenCls('w3-show-small'), setOpenVal('w3-hide-small')) : (setOpenCls('w3-hide-small'), setOpenVal('w3-hide-small'))}>
                                Why Parentwise
                        </button>
                        {openCls == 'w3-hide-small' ?
                            <span><img style={{marginBottom: "5px"}} src={UpArrow} /></span> :
                            <img src={DownArrow} />}
                        <Link to="/join-the-movement" className="about-us-button">Join the Movement</Link>
                        <Link to="/our-story" style={{marginLeft: "15px"}} className="about-us-button">Our Story</Link>
                    </div>
                    <div className={`${openCls} w3-bar-block w3-hide-medium w3-hide-large`} style={{marginLeft: "18px", marginTop: "8px", zIndex: 10}}>
                        <button 
                            onClick={() => (setOpenCls('w3-show-small'), setOpenVal('w3-hide-small'))} 
                            className={`w3-bar-item about-us-button ${openCls == 'w3-show-small' && openVal == 'w3-hide-small' ? 'about-us-sub-menu-1' : 'about-us-sub-menu'}`}>
                                Our Beliefs
                        </button>
                        <button  
                            onClick={() => openVal == 'w3-hide-small' ? setOpenVal('w3-show-small') : setOpenVal('w3-hide-small')}
                            className={`w3-bar-item about-us-button ${openVal == 'w3-show-small' ? 'about-us-sub-menu-1' : 'about-us-sub-menu'}`}>
                            Our Values
                        </button>
                    </div>
                    <div id="parentwise" className={`w3-content about-us-main-div about-us-padding-pwise ${openCls == 'w3-show-small' ? 'w3-hide-small' : 'w3-show-small'}`}>
                        <div className="about-us-title">{data.title}</div>
                        <div className="about-us-content">{data.description}</div>
                    </div>
                    <div id="beliefs" className={`ab-us-bel-ma ${openVal == 'w3-show-small' ? 'w3-hide-small' : `${openCls}`}`}>
                        <div className="w3-content">
                            <div className="about-us-title-sub padding">{data.subTitle1}</div>
                                {OurBeliefs}
                        </div>
                    </div>
                    <div id="values" className={`about-us-padding ${openVal}`}>
                        <div className="ab-our-values">
                            <div className="about-us-title-sub">{data.subTitle2}</div>
                            <ValueCard data={ValueCardContent.data.valuecard} />
                        </div>
                    </div>
                </div>
            </Layout>
        </div>     
    )
}

export default AboutUs